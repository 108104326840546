.ant-spin-dot-item {
  background-color: "#16A07F" !important;
  color: "#16A07F" !important
}

.footer {
  background-color: white;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.footer-content {
  text-align: center;
}

.footer-text-blue {
  color: #1264e0;
  font-family: Poppins;
  font-size: 9px;
  font-weight: 500;
  line-height: 14px;
}

.footer-text-gray {
  color: #a3b4cb;
  font-family: Poppins;
  font-size: 9px;
  font-weight: 500;
  line-height: 14px;
}
