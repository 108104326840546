@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap');

html, body {
  height: 100%;
  margin: 0px;
  padding: 0px;
  position: relative;
  font-family: 'Poppins' !important;
}
.antd-layout {
  font-family: inherit !important;
}

a, p, span, h1, h2, h3, h4, h5, tr, th, td{
  font-family: 'Poppins' !important;
}

.wrapper {
  margin-left: auto;
  margin-right: auto;
  background-color: #1264E0;
  height: 100%;
}

.big-wrapper {
  background-color: #1264E0;
}

.big-wrapper-title {
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
  padding: 30px 0px;
  margin: 0px;
}

.wrapper-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  
}

.flexbox-space-between {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.flexbox-start {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
}

.flexbox-center {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.flexbox-end {
  display: flex;
  flex-wrap: wrap;
  justify-content: end;
}

.topia-logo {
  width: 60px;
  padding: 10px 10px;
}

.dark-blue-container {
  background-color: #0547AC;
  
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}

.green-container {
  background-color: #16A07F;
  
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}

.gray-container {
  background-color: #F1F1F1;
  
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}

.gray-container-content {
  padding: 30px 20px;
}

.intro-description {
  padding: 35px 20px;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: white;
}

.main-container {
  background-color: #EAF0FB;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}

.title-text {
  color: #1264e0;
  font-size: 14px;
  font-weight: 600;
}

.button-text {
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  text-transform: uppercase;
}

.mid-block {
  margin-bottom: 2rem;
}

.final-block {
  margin-bottom: 4rem;
}

.click-image {
  cursor: pointer;
}

.reservation-drawer {
  max-width: 450px;
  margin-left: auto;
  margin-right: auto;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}

.ant-drawer-content-wrapper {
  max-width: 450px !important;
  margin-left: auto !important;
  margin-right: auto !important;
  border-top-left-radius: 25px !important;
  border-top-right-radius: 25px !important;
}

.reservation-drawer-header {
  text-align: center;
}

.reservation-drawer-header .title {
  color: #414141;
  font-size: 11px;
  font-weight: 400;
  line-height: 16px;
}

.reservation-drawer-header .description {
  color: #16a07f;
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
}

.drawer-container {
  background-color: #EAF0FB;
  height: 100%;
  padding: 20px;
}

.drawer-time-header {
  color: #16a07f;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}

.drawer-time-description {
  color: #000000;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
}

.drawer-partners-title {
  color: #414141;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  border-bottom: 1px solid #C5CDDB;
  text-transform: uppercase;
}

.partners-list {
  list-style: none;
  overflow-y: auto;
  padding-left: 5px;
}

.partners-list-title {
  color: #324664;
  font-size: 16px;
  font-weight: 500;
  line-height: 5px;
}

.partners-list-description {
  color: #414141;
  font-size: 12px;
  font-weight: 400;
  line-height: 5px;
  text-transform: uppercase;
}

.reservation-card {
  width: 100%;
  height: 141px;
  border-radius: 12px;
  background: #ffffff;
  background-blend-mode: normal;
  box-shadow: 0px 8px 12px rgba(100,100,100,0.14);
}

.reservation-card-container {
  padding: 0px 0px;
}

.reservation-card-type {
  color: #4d4d4d;
  font-size: 14px;
  font-weight: 500;
}

.ant-card-body {
  padding: 10px 20px !important;
}

.schedule-wrapper {
  margin-top: 2rem;
}