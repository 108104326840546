@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');
body {
  font-family: 'Poppins'  !important;
}

code {
  font-family: 'Poppins'  !important;
}

.antd-layout {
  font-family: inherit !important;
}

